import { Kiosk } from "domains/kiosk/aggregates/Kiosk";
import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import styles from "./IdleTime.module.scss";

const DELAY = 1000;
const NO_TIMER = 9999;
const EXPIRATION_DEFAULT_TIME = 30;

function useInterval(callback: () => void, delay: number) {
  const savedCallback = useRef<() => void>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    let id = setInterval(() => {
      savedCallback.current && savedCallback.current();
    }, delay);
    return () => clearInterval(id);
  }, [delay]);
}

interface IdleContext {
  resetCounter: () => void;
  handleActiveIdleTimer: () => void;
  handleActiveDefaultIdleTimer: () => void;
  handleDisableIdleTimer: () => void;
}

const IdleContext = createContext<IdleContext>({} as IdleContext);

interface IdleContextProviderProps {
  kiosk: Kiosk | null;
  children: ReactNode;
}

export const IdleProvider: FC<IdleContextProviderProps> = ({
  children,
  kiosk,
}) => {
  const { replace } = useHistory();

  const [isActive, setIsActive] = useState(false);
  const [expirationTime, setExpirationTime] = useState(NO_TIMER);

  const handleActiveIdleTimer = useCallback(() => {
    setExpirationTime(kiosk?.expirationTime ?? NO_TIMER);
    setIsActive(true);
  }, [kiosk]);

  const handleActiveDefaultIdleTimer = useCallback(() => {
    setExpirationTime(kiosk?.expirationTime ?? EXPIRATION_DEFAULT_TIME);
    setIsActive(true);
  }, [kiosk]);

  const handleDisableIdleTimer = useCallback(() => {
    setIsActive(false);
  }, []);

  const [counter, setCounter] = useState<number>(expirationTime);

  const intervalCallback = useCallback(() => {
    isActive && setCounter((prev) => prev - 1);
  }, [isActive]);

  useInterval(intervalCallback, DELAY);

  const onTimerFinish = useCallback(() => {
    replace("/");
  }, [replace]);

  const resetCounter = useCallback(() => {
    console.log("resetTimer");
    setCounter(expirationTime);
  }, [expirationTime]);

  useEffect(() => {
    isActive && resetCounter();
  }, [isActive, resetCounter]);

  useEffect(() => {
    if (counter <= 0) {
      if (isActive) {
        setCounter(expirationTime);
        onTimerFinish();
      }
    }
  }, [counter, expirationTime, isActive, onTimerFinish]);

  useEffect(() => {
    window.addEventListener("click", resetCounter);
    return () => {
      window.removeEventListener("click", resetCounter);
    };
  }, [resetCounter]);

  const renderCounter = useMemo(
    () => (isActive && counter >= 0 && counter <= 10 ? counter : ""),
    [counter, isActive]
  );

  return (
    <IdleContext.Provider
      value={{
        resetCounter,
        handleActiveDefaultIdleTimer,
        handleActiveIdleTimer,
        handleDisableIdleTimer,
      }}
    >
      {children}
      <div className={styles.IdleTimeContainer}>{renderCounter}</div>
    </IdleContext.Provider>
  );
};

export const useIdle = () => {
  const context = useContext(IdleContext);
  return context;
};
