import {
  Dialog,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
} from "@material-ui/core";
import { ProdutoTipo } from "Infrastructure/repositories/api/PedidoMeep";
import { WalletRepository } from "Infrastructure/repositories/api/WalletRepository";
import { useAppInsights } from "Infrastructure/repositories/appInsights/AppInsights";
import { OrderRepositoryLocalStorage } from "Infrastructure/repositories/localStorage/OrderRepositoryLocalStorage";
import { useCatalog } from "application/contexts/catalog/CatalogContext";
import { useKiosk } from "application/contexts/kiosk/KioskContext";
import { useUi } from "application/contexts/ui/UIContext";
import { ErrorCodeType } from "application/models/IError";
import {
  IModalNotaFiscalRef,
  ModalNotaFiscal,
} from "areas/catalog/components/modalNotaFiscal/ModalNotaFiscal";
import { BotaoVoltar } from "components/botaoVoltar/BotaoVoltar";
import { TecladoVirtual } from "components/tecladoVirtual/TecladoVirtual";
import { moneyMask, moneyToFloat } from "corss-cutting/masks/money";
import { Payment } from "domains/order/agreggates/payment/payment";
import { ICardReaderStore } from "modules/cardReader/domain/interfaces/IUseCardReaderStore";
import { useCardReaderStore } from "modules/cardReader/infra/store/CardReaderStore";
import { WalletRechargeUseCase } from "modules/cashless/application/useCases/WalletRechargeUseCase";
import { IWalletExtract } from "modules/cashless/domain/models/IWallet";
import { IEmitedNfce } from "modules/order/domain/models/INfce";
import { IOrder } from "modules/order/domain/models/IOrder";
import { IOrderLog } from "modules/order/domain/models/IOrderLog";
import { IPedidoPosDTO } from "modules/order/domain/models/IPedidoPosDTO";
import { IOrderProviderRef } from "modules/order/presentation/OrderProvider";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { GetError } from "utils/GetError";
import { v4 } from "uuid";
import styles from "./Wallet.module.scss";
import Extract from "./components/extract/Extract";
import { MeepOrderRepository } from "Infrastructure/repositories/api/OrderRepository";
import { IWalletRechargeModel } from "modules/payment/domain/models/IWalletTransaction";
import { usePrinter } from "application/contexts/printer/PrinterContext";
import { useIdle } from "application/contexts/idle/IdleContext";

const orderRepositoryDB = OrderRepositoryLocalStorage();
const orderRepository = MeepOrderRepository();

export interface IWallet {
  orderProvider: React.RefObject<IOrderProviderRef>;
}

const WalletPage: FC<IWallet> = ({ orderProvider }) => {
  const refNotaFiscalPrint = useRef<IModalNotaFiscalRef>(null);
  const walletTag = useRef<string | null>(null);
  const enabled = useRef(true);

  const history = useHistory();
  const { kiosk } = useKiosk();
  const { hideLoading, showLoading, toast, toastFullscreen } = useUi();
  const { getConfigurationProduct } = useCatalog();
  const { addLog } = useAppInsights();
  const { printWalletRecharge } = usePrinter();
  const { handleActiveDefaultIdleTimer, resetCounter } = useIdle();

  const [_enableSensor, disableSensor, tag, clear] = useCardReaderStore(
    (state: ICardReaderStore) => [
      state.enableSensor,
      state.disableSensor,
      state.tag,
      state.clearCardReader,
    ]
  );

  const [rechargeModal, setRechargeModal] = useState(false);
  const [extract, setExtract] = useState<IWalletExtract | null>(null);
  const [rechargeValue, setRechargeValue] = useState("0.00");

  useEffect(() => {
    handleActiveDefaultIdleTimer();
  }, [handleActiveDefaultIdleTimer])
  

  useEffect(() => {
    _enableSensor("Wallet");

    return () => {
      disableSensor();
    };
  }, [disableSensor, _enableSensor]);

  const onpressback = useCallback(() => {
    setRechargeValue("00.0");
  }, []);

  const onpressKeyboard = useCallback((key: string) => {
    setRechargeValue((prev) => prev + key);
  }, []);

  const onPressClear = useCallback(() => {}, []);

  const onClickCloseRechargeDialog = useCallback(() => {
    setRechargeModal(false);
    setRechargeValue("00.0");
  }, []);

  const addOrderLog = useCallback(
    async (
      order: IOrderLog | null,
      message: string,
      pedidoPOS?: IPedidoPosDTO,
      payment?: Payment,
      nfce?: IEmitedNfce[],
      error?: any
    ) => {
      try {
        if (order) {
          let orderLog = { ...order, payment, pedidoPOS, nfce, error };
          addLog("Wallet", { message, orderLog });
          await orderRepositoryDB.add(orderLog, message);
        }
      } catch (error) {
        GetError(
          error,
          "addOrderLog",
          "Wallet",
          "Falha ao adicionar log",
          ErrorCodeType.GENERAL
        );
      }
    },
    [addLog]
  );

  const getExtract = useCallback(
    async (tag: string) => {
      if (kiosk) {
        try {
          showLoading();
          const walletService = WalletRepository();
          const responseExtract = await walletService.getWalletExtract(
            kiosk.localId,
            tag
          );
          if (responseExtract) {
            setExtract(responseExtract);
            resetCounter();
          } else {
            setExtract(null);
          }
        } catch (error) {
          enabled.current = true;
          clear();
          toast("Falha ao obter extrato, tente novamente", "error");
        } finally {
          hideLoading();
        }
      }
    },
    [clear, hideLoading, kiosk, resetCounter, showLoading, toast]
  );

  useEffect(() => {
    if (tag && enabled.current) {
      enabled.current = false;
      walletTag.current = tag;
      getExtract(tag);
    }
  }, [getExtract, tag]);

  const onClickBack = useCallback(() => {
    history.replace("/");
  }, [history]);

  const onTimerFinish = useCallback(() => {
    history.replace("/");
  }, [history]);

  const criarOrderRecarga = useCallback(
    (value: number, extract: IWalletExtract) => {
      if (getConfigurationProduct(ProdutoTipo.Recarga)) {
        const newOrderId = v4();
        const newOrder: IOrder = {
          id: newOrderId,
          cartDate: new Date().toISOString(),
          orderItems: [
            {
              id: v4(),
              name: getConfigurationProduct(ProdutoTipo.Recarga)?.name ?? "",
              category: "",
              price: value,
              realPrice: value,
              totalPrice: value,
              description: null,
              productPrice: value,
              productId: getConfigurationProduct(ProdutoTipo.Recarga)?.id ?? "",
              productType: ProdutoTipo.Recarga,
              quantity: 1,
              imageUrl: "",
              barcode: null,
              hideCatalog: false,
              orderComposition: null,
              compositions: null,
              printerName: null,
            },
          ],
          totalValue: value,
          friendlyId:
            kiosk?.kioskName.replace(/\D/gm, "") +
            " " +
            newOrderId.substr(0, 4),
          createdAt: new Date().toLocaleString(),
          customer: {
            name: extract.meepUser.name,
            orderId: newOrderId,
            tag: extract.tag,
            number: extract.tag,
            orderPad: extract.tag,
          },
        };
        return newOrder;
      } else {
        toast("Falha ao adicionar crédito", "error");
      }
    },
    [getConfigurationProduct, kiosk, toast]
  );

  const onSucessPayment = useCallback(
    async (order: IOrder, payment: Payment) => {
      if (kiosk) {
        showLoading();
        setRechargeModal(false);
        setRechargeValue("00.0");

        const rechargePayload: IWalletRechargeModel = {
          localId: kiosk.localId,
          totemId: kiosk.id,
          userId: extract?.meepUser.id ?? "",
          walletId: extract?.walletId ?? "",
          orderId: order.id,
          value: order.totalValue,
          paymentType: payment.paymentMethod,
          transaction: JSON.stringify(payment),
        };

        addOrderLog(
          {
            ...order,
            walletRechargeModel: rechargePayload,
            isSynchronized: false,
          },
          "Recarga wallet iniciada",
          undefined,
          payment
        );

        try {
          const walletService = WalletRepository();
          const walletTransaction = await WalletRechargeUseCase(
            walletService,
            rechargePayload
          );

          addOrderLog(
            {
              ...order,
              walletRechargeResult: walletTransaction,
              isSynchronized: true,
            },
            "Recarga wallet sincronizada",
            undefined,
            payment
          );

          printWalletRecharge({
            balance: walletTransaction.balance,
            orderId: walletTransaction.orderId,
            order: order,
            walletId: walletTransaction.walletId,
          });

          toastFullscreen("Pagamento realizado com sucesso", "success", {
            onClose: () => {
              history.replace("/");
            },
          });
          hideLoading();
        } catch (error) {
          orderRepository.savePendentConfirmationWalletRechargeOrders([
            { ...rechargePayload, date: new Date().toISOString() },
          ]);
          addOrderLog(
            { ...order },
            "Falha ao confirmar recarga Wallet",
            undefined,
            payment,
            undefined,
            error
          );
          toastFullscreen(
            "Pagamento aprovado, estamos tentando enviar o pedido para o servidor.",
            "warning",
            {
              onClose: () => {
                history.replace("/");
              },
            }
          );
          hideLoading();
        }
      }
    },
    [
      kiosk,
      showLoading,
      extract,
      addOrderLog,
      printWalletRecharge,
      toastFullscreen,
      hideLoading,
      history,
    ]
  );

  const onClickAdd = useCallback(async () => {
    if (moneyToFloat(rechargeValue) >= 1) {
      if (extract) {
        const newOrder = criarOrderRecarga(
          moneyToFloat(rechargeValue),
          extract
        );
        if (newOrder) {
          await addOrderLog(
            newOrder,
            `Adicionar crédito Wallet: ${extract.tag}`,
            undefined
          );
          try {
            const result = await orderProvider.current?.startPayment(
              newOrder,
              "WalletRecharge"
            );
            if (result) {
              addOrderLog(
                result.order,
                "Pagamento aprovado",
                undefined,
                result.payment
              );
              onSucessPayment(result?.order, result?.payment);
            } else {
              addOrderLog(newOrder, "Falha no pagamento");
              toast("Falha no pagamento", "error");
              await addOrderLog(newOrder, `Falha no pagamento`, undefined);
              GetError(
                new Error("Falha no pagamento"),
                "onClickPagarComanda",
                "Cashless",
                undefined,
                ErrorCodeType.PAYMENT
              );
            }
          } catch (error) {
            await addOrderLog(newOrder, `Erro no pagamento`, undefined);
            GetError(
              error,
              "onClickPagarComanda",
              "Cashless",
              undefined,
              ErrorCodeType.PAYMENT
            );
          }
        }
      } else {
        toast("Falha ao adicionar crédito", "warning");
      }
    }
  }, [
    addOrderLog,
    criarOrderRecarga,
    extract,
    onSucessPayment,
    orderProvider,
    rechargeValue,
    toast,
  ]);

  return (
    <div id={styles.Wallet}>
      <BotaoVoltar onClick={onClickBack} />
      <div className={styles.content}>
        <img
          className={styles.logo}
          src={require("../../assets/images/logo-meep.png")}
          alt="logo"
        />
        {!extract && (
          <>
            <img
              className={styles.gif}
              src={require("../../assets/images/RFID.gif")}
              alt="RFID"
            />
            <div className={styles.title}>
              Aproxime o cartão/pulseira do leitor
            </div>
          </>
        )}

        {extract && (
          <Extract
            extract={extract}
            onClickAdd={() => {
              setRechargeModal(true);
            }}
          />
        )}

        <ModalNotaFiscal ref={refNotaFiscalPrint} />

        <Dialog open={rechargeModal}>
          <DialogTitle>
            {" "}
            <div className="titulo-recharge">
              Recarregar cartão{" "}
              {extract?.meepUser.name
                ? "de " + extract.meepUser.name.split(" ")[0]
                : ""}
            </div>
          </DialogTitle>
          <DialogContent>
            <div className="instrucao-recharge">Insira o valor da recarga</div>
            <div className="input-recharge">
              <div className="value-recharge">{moneyMask(rechargeValue)}</div>
              <div className="back-recharge" onClick={onpressback}>
                <Icon className="icon-recharge" fontSize="large">
                  backspace
                </Icon>
              </div>
            </div>
            <div className="erro-recharge" onClick={onpressback}></div>
            <TecladoVirtual
              type="numeric"
              onPressBack={onpressback}
              onKeyPress={onpressKeyboard}
              onPressClear={onPressClear}
            />
            <IconButton
              onClick={onClickCloseRechargeDialog}
              style={{ position: "absolute", right: 8, top: 8 }}
            >
              <Icon fontSize="large">close</Icon>
            </IconButton>
            <div className="container-button">
              <div
                onClick={onClickAdd}
                className="button"
                style={{ background: "#1ba" }}
              >
                Adicionar Credito
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

export default WalletPage;
